.protocol-check {
    position: relative;
    font-family: 'PingFangSC-Regular', 'PingFang SC';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #999;
    text-align: left;

    &.shake {
        animation: shake-horizontal 1s ease-in-out 0s;
    }

    .checkbox {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 7px;
        background-image: url('../image/checkbox.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        vertical-align: middle;
        opacity: 0.45;
    }

    .checkbox.checked {
        background-image: url('../image/checkboxchecked.svg');
        opacity: 1;
    }

    a {
        color: #797979;
    }
}

@keyframes shake-horizontal {
    from {
        transform: translate(0, 0);
    }

    10% {
        transform: translate(-10px, 0);
    }

    20% {
        transform: translate(10px, 0);
    }

    30% {
        transform: translate(-10px, 0);
    }

    40% {
        transform: translate(10px, 0);
    }

    50% {
        transform: translate(-10px, 0);
    }

    60% {
        transform: translate(10px, 0);
    }

    70% {
        transform: translate(-10px, 0);
    }

    80% {
        transform: translate(10px, 0);
    }

    90% {
        transform: translate(-10px, 0);
    }

    to {
        transform: translate(0, 0);
    }
}
