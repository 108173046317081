.ace-login-hd {
    padding-top: 48px;
    font-family: 'PingFangSC-Medium', 'PingFang SC';
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
}

.ace-login-form {
    padding: 16px 24px;
}

.ace-login-item {
    display: flex;
    height: 52px;
    margin-bottom: 16px;
    border-radius: 26px;
    line-height: 52px;
    background: #f9f9fb;
}

.ace-login-item .am-list-item.am-input-item {
    width: 100%;
    height: auto;
    padding: 0;
    background: transparent;
}

.ace-login-item .am-list-item .am-input-control input {
    width: 100%;
    padding: 15px 24px;
    border: 0;
    outline: none;
    font-family: 'PingFangSC-Regular', 'PingFang SC';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.85);
    background: transparent;
}

.ace-login-item .am-list-item.am-input-error .am-input-control input {
    color: red;
}

.ace-login-areacode {
    width: 90px;
    font-family: 'PingFangSC-Regular', 'PingFang SC';
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
}

.ace-login-areacode .am-icon {
    margin-top: 14px;
    float: right;
}

.ace-login-item .am-list-item .am-input-extra {
    max-height: 100%;
    line-height: 50px;
}

.ace-login-verifycode {
    width: 100px;
    padding-right: 10px;
    font-family: 'PingFangSC-Regular', 'PingFang SC';
    font-size: 14px;
    font-weight: 400;
    color: #ff6e00;
    text-align: right;
}

.ace-login-verifycode.disabled {
    color: #ffb780;
}

.ace-login-protocol {
    padding: 8px 0 16px;
    font-family: 'PingFangSC-Regular', 'PingFang SC';
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
}

.ace-login-protocol > a {
    color: rgba(255, 110, 0, 1);
}
