.drawer-dialog {
    top: 10%;
}

.drawer-dialog.height_auto {
    top: auto;
}

.drawer-dialog.circle .am-modal-content {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.drawer-dialog-box {
    display: flex;
    flex-flow: column;
    align-content: space-between;
    height: 100%;
}

.drawer-dialog-tt {
    padding: 16px 0;
    font-family: 'PingFangSC-Medium', 'PingFang SC';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
}

.drawer-dialog-bd {
    height: 100%;
    overflow: auto;
}

.drawer-close-bar {
    position: absolute;
    top: 0;
    left: 50%;
    width: 48px;
    height: 4px;
    margin-left: -24px;
    padding: 12px 0;
}

.drawer-close-bar::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.25);
}

.drawer-close-btn {
    position: absolute;
    top: 12px;
    z-index: 10;
    width: 24px;
    height: 24px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
}

.drawer-close-btn.left {
    left: 12px;
}

.drawer-close-btn.right {
    right: 12px;
}

.drawer-close-btn .iconfont {
    font-size: 24px;
}
