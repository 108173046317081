.loading {
  width: 100%;
  height: 100%;
}
.loading-img {
  display: block;
  width: 158px;
  height: 158px;
  margin: 132px auto 0;
}
.loading-text {
  margin-top: 8px;
  font-family: 'PingFangSC-Regular';
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
}
