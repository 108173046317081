// @import './normalize.css';
// @import './button.css';

@import '~antd-mobile/dist/antd-mobile.less';
@import './reset.less';
@import './font-icon.css';
@import './button.css';

html {
    background-color: #f6f7f8;
}

body {
    max-width: 375px;
    margin: 0 auto;
    font-family: 'PingFangSC-Medium', 'PingFang SC';
    font-size: 20px;
    background-color: #f6f7f8;
}

#root {
    box-sizing: border-box;
    min-height: 100vh;
}

.fullscreen #root {
    padding-bottom: 34px;
}

a:hover {
    text-decoration: none;
}
