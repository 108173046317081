/* 重写antd-mobail button */
.my-button.am-button {
  justify-content: center;
  border-radius: 48px;
  overflow: visible;
  font-size: 16px;
}
/* prettier-ignore */
.my-button.am-button::before {
  border: 1PX solid rgba(0, 0, 0, 0.25) !important;
  border-radius: 48px !important;
}
.my-button.am-button.am-button-small {
  border-radius: 32px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
.my-button.am-button.am-button-small::before {
  border-radius: 32px !important;
}
.my-button + .my-button.am-button.am-button-small.am-button-inline {
  margin-left: 8px;
}
.my-button.am-button.am-button-primary {
  color: #fff;
}
/* prettier-ignore */
.my-button.am-button.am-button-primary::before {
  border: 1PX solid #ff6e00 !important;
}
.my-button.am-button.am-button-ghost {
  color: #ff6e00;
  background-color: #fff;
}
/* prettier-ignore */
.my-button.am-button.am-button-ghost::before {
  border: 1PX solid #ff6e00 !important;
}
